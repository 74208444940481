import React from "react";
import ArticleCard from "../components/ArticleCard";
import ButtonOutlined from "../components/ButtonOutlined";
import MainArticle from "../components/MainArticle";
import Layout from "../components/Layout";
import SideBar from "../components/SideBar";
import SEO from "../components/SEO";
import { useStaticQuery, graphql } from "gatsby";

//NÃO CONSEGUI ADICIONAR PARAMETRO NA STRING DE QUERY DO GRAPHQL
// const ArticlesPerPage = parseInt(process.env.ARTICLES_PER_PAGE);
const Index = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulArticle(
          sort: { fields: publishedDate, order: DESC }
          limit: 5
        ) {
          totalCount
          edges {
            node {
              categories {
                title
                slug
              }
              articleCover {
                title
                fluid(maxWidth: 1028) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              title
              description
              slug
              publishedDate(formatString: "MMMM, YYYY", locale: "pt-BR")
            }
          }
        }
      }
    `
  );
  // console.log(data.allContentfulArticle.edges[0].node.articleCover.fluid);

  return (
    <Layout>
      <SEO />
      <MainArticle
        title={data.allContentfulArticle.edges[0].node.title}
        description={data.allContentfulArticle.edges[0].node.description}
        publishedDate={data.allContentfulArticle.edges[0].node.publishedDate}
        categories={data.allContentfulArticle.edges[0].node.categories}
        alt={data.allContentfulArticle.edges[0].node.articleCover.title}
        slug={data.allContentfulArticle.edges[0].node.slug}
        fluid={data.allContentfulArticle.edges[0].node.articleCover.fluid}
      />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12 relative">
          {data.allContentfulArticle.edges.slice(1).map((edge, i) => {
            return (
              <ArticleCard
                key={i}
                title={edge.node.title}
                description={edge.node.description}
                publishedDate={edge.node.publishedDate}
                categories={edge.node.categories}
                alt={edge.node.articleCover.title}
                slug={edge.node.slug}
                fluid={edge.node.articleCover.fluid}
              />
            );
          })}
          <div className="mt-2 flex justify-between">
            <ButtonOutlined text="PÁGINA ANTERIOR" to="/1" />
          </div>
        </div>
        <div className="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};

export default Index;
