import React from "react";
import CallToAction from "../components/CallToAction";
import { Link } from "gatsby";
import CategoriesRibbon from "../components/CategoriesRibbon";
import Img from "gatsby-image";

const MainArticle = ({ title, categories, description, slug, fluid, alt }) => {
  return (
    <div className="h-96 mt-2 relative ">
      <Img
        fluid={fluid}
        alt={alt}
        title={alt}
        className="h-96 w-full object-cover object-center rounded-b-md"
      />
      <div className="h-96 w-full md:w-1/2 absolute top-0 bg-gray-600 opacity-50 rounded-b-md" />
      <div className="h-96 w-full md:w-1/2 absolute top-0">
        <div className="flex flex-col h-96 justify-center px-6">
          <Link to={slug}>
            <h2 className="mb-2 text-2xl font-semibold text-center text-white hover:underline">
              {title}
            </h2>
          </Link>
          <h3 className="text-xl font-medium text-white">{description}</h3>
          <div className="flex mb-2">
            <CategoriesRibbon categories={categories} />
          </div>
          {/* call to action com social media */}
          <CallToAction to={slug} />
        </div>
      </div>
    </div>
  );
};

export default MainArticle;
